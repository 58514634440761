import vector from '../../assets/images/vector.svg';
import red_quotes from '../../assets/images/red_quotes.svg';
import school_blue from '../../assets/images/school-blue.svg';
import hand_blue from '../../assets/images/hand-blue.svg';
import school_white from '../../assets/images/school-white.svg';
import hand_white from '../../assets/images/hand-white.svg';
import next_arrow from '../../assets/images/next-arrow.svg';
import next_arrow_blue from '../../assets/images/next-arrow-blue.svg';
import blue_bg from '../../assets/images/blue-slides-bg.png';
import green_bg from '../../assets/images/green-slides-bg.png';
import image_69 from '../../assets/images/image69.png';
import image_70 from '../../assets/images/image-70.png';
import banner_image_rounded_1 from '../../assets/images/home_image_1_rounded.svg';
import banner_image_rounded_2 from '../../assets/images/home_image_2_rounded.svg';
import banner_decor_1_purple from '../../assets/images/purple_decor_1.svg';
import banner_decor_2_purple from '../../assets/images/purple_decor_2.svg';
import banner_decor_3_purple from '../../assets/images/star_pink.svg';
import banner_decor_1_green from '../../assets/images/green_decor_1.svg';
import banner_decor_2_green from '../../assets/images/green_decor_2.svg';
import banner_decor_3_green from '../../assets/images/star_green.svg';

class HomeCarousel extends HTMLElement {
    connectedCallback() {
        var carouselItem1 = `
            <div id="carousel-item-1" class="item">
                <div class="property-default">
                    <div class="overlap-group bg-purple">
                        <div class="banner_decor_1">
                            <img src="${banner_decor_1_purple}" />
                        </div>
                        <div class="banner_decor_2">
                            <img src="${banner_decor_2_purple}" />
                        </div>
                        <div class="banner_decor_3">
                            <img src="${banner_decor_3_purple}" />
                        </div>
                        <div class="frame">
                            <div class="div">
                                <p class="text-wrapper">
                                    Launch Your Tech Career with 
                                    <span class="text-wrapper-easy-donate">
                                        CCpathways
                                    </span>
                                    Community
                                </p>
                                <p class="p">
                                    Gain Practical Experience and Secure Your 
                                    Future in the Software Technology Space
                                </p>
                            </div>
                            <div class="frame-wrapper">
                                <div class="frame-2">
                                    <a href="/contact-us.html">
                                        <div class="btn-5">
                                            <div class="sign-up valign-text-middle circularstd-bold-white-16px">
                                                Contact Us
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="banner-image">
                            <img src="${banner_image_rounded_1}" />
                        </div>
                    </div>
                </div>
            </div>
        `;
        var carouselItem2 = `
            <div id="carousel-item-2" class="item">
                <div class="property">
                    <div class="overlap-group bg-green">
                        <div class="banner_decor_1">
                            <img src="${banner_decor_1_green}" />
                        </div>
                        <div class="banner_decor_2">
                            <img src="${banner_decor_2_green}" />
                        </div>
                        <div class="banner_decor_3">
                            <img src="${banner_decor_3_green}" />
                        </div>
                        <div class="frame-9">
                            <div class="div">
                                <p class="text-wrapper">Empowering Charities Worldwide</p>
                                <p class="p">
                                    Unleash the Power of Global Generosity | Fundraise, Volunteer, 
                                    and Engage with Cutting-edge Digital Strategies
                                </p>
                            </div>
                            <div class="frame-wrapper">
                                <div class="frame-2">
                                    <a href="/contact-us.html">
                                        <div class="btn-5">
                                            <div class="sign-up valign-text-middle circularstd-bold-white-16px">
                                                Contact Us
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="banner-image">
                            <img src="${banner_image_rounded_2}" />
                        </div>
                    </div>
                </div>
            </div>
        `;

        this.innerHTML = `
        <div class="box">
            <div class="aaa">
                <div>
                    ${carouselItem1}
                </div>
            </div>
        </div>
        `;
    }
}

class WhoWeServe extends HTMLElement {
    connectedCallback() {
        this.innerHTML = `
        <div class="frame-47">
            <div class="frame-31">
                <div class="frame">
                    <div class="frequently-asked-questions circularstd-bold-prussian-blue-36px">
                    Who we serve
                    </div>
                </div>
            </div>
            <div class="who-we-serve">
                <div onmouseover="showSchoolHover()" onmouseout="hideSchoolHover()">
                    <a href="/community-colleges.html" id="school" >
                        <div class="frame-631039">
                            <div class="frame-631030-1">
                                <img class="volunteer_activism" src="${school_blue}" alt="volunteer_activism" />
                            </div>
                            <div class="frame-54-1 frame-54-4">
                                <div class="foreign-nonprofits">Community Colleges</div>
                                <p class="the-easy-donate-star-1 gilroy-medium-oxford-blue-18px">
                                Empower Students: Explore Our College Apprenticeship & Internship Program
                                </p>
                            </div>
                            <img class="frame-blue" src="${next_arrow_blue}" alt="Frame" />
                        </div>
                    </a>
                    <a href="/community-colleges.html" id="school_hover" style="display: none" >
                        <div class="frame-631037">
                            <div class="frame-631030">
                                <img class="school" src="${school_white}" alt="school" />
                            </div>
                            <div class="frame-54-1 frame-54-4">
                                <div class="community-colleges">Community Colleges</div>
                                <p class="the-easy-donate-star gilroy-medium-oxford-blue-18px">
                                    Empower Students: Explore Our College Apprenticeship & Internship Program
                                </p>
                            </div>
                            <img class="frame-blue" src="${next_arrow}" alt="Frame" />
                        </div>
                    </a>
                </div>
                <div onmouseover="showHandHover()" onmouseout="hideHandHover()">
                    <a href="/nonprofits.html" id="hand">
                        <div class="frame-631039">
                            <div class="frame-631030-1">
                                <img class="volunteer_activism" src="${hand_blue}" alt="volunteer_activism" />
                            </div>
                            <div class="frame-54-1 frame-54-4">
                                <div class="foreign-nonprofits">Foreign Nonprofits</div>
                                <p class="the-easy-donate-star-1 gilroy-medium-oxford-blue-18px">
                                Change the World: Give to Foreign Nonprofits with Ease
                                </p>
                            </div>
                            <img class="frame-blue" src="${next_arrow_blue}" alt="Frame" />
                        </div>
                    </a>
                    <a href="/nonprofits.html" id="hand_hover" style="display: none">
                        <div class="frame-631037">
                            <div class="frame-631030">
                                <img class="school" src="${hand_white}" alt="school" />
                            </div>
                            <div class="frame-54-1 frame-54-4">
                                <div class="community-colleges">Foreign Nonprofits</div>
                                <p class="the-easy-donate-star gilroy-medium-oxford-blue-18px">
                                    Change the World: Give to Foreign Nonprofits with Ease
                                </p>
                            </div>
                            <img class="frame-blue" src="${next_arrow}" alt="Frame" />
                        </div>
                    </a>
                </div>
            </div>
        </div>
        `;
    }
}

class StoriesOfImpact extends HTMLElement {
    connectedCallback() {
        this.innerHTML = `
        <div class="stories-of-impact frame-95-1">
            <div class="frame">
                <div class="frequently-asked-questions circularstd-bold-prussian-blue-36px">
                Stories of Impact
                </div>
            </div>
            <div class="frame-1">
                <img class="image-69-mob" id="image-69-mob" src="${image_69}" alt="image 69" />
                <div class="frame-54">
                    <div class="number circularstd-book-normal-gull-gray-36px">01</div>
                    <div class="place circularstd-bold-prussian-blue-36px">Empowering Youth and Building Bridges</div>
                    <p class="enable-everyone-to-i material-themebodylarge">
                    ccpathways.org bridges the gap, empowering youth through our College Apprenticeship & Internship Program. Together, we build brighter futures, connecting students with real-world experience and nurturing the leaders of tomorrow.
                    </p>
                    <div class="rectangle-25"></div>
                    <div class="rectangle-26"></div>
                </div>
                <img class="image-69" id="image-69-web" src="${image_69}" alt="image 69" />
            </div>
            <div class="frame-1">
                <img class="image-69-mob" id="image-70-mob" src="${image_70}" type="image/webp" alt="image 69" />
                <img class="image-69" id="image-70-web" src="${image_70}" type="image/webp" alt="image 69" />
                <div class="frame-54">
                    <div class="number-1 circularstd-book-normal-gull-gray-36px">02</div>
                    <div class="vision circularstd-bold-prussian-blue-36px">Building Resilience Against Human Trafficking</div>
                    <p class="build-a-state-of-the material-themebodylarge">
                    At ccpathways.org, we unite causes and donors. We help non-profits worldwide raise funds in the US, facilitating tax-exempt contributions and fostering positive global change. Join us in making a difference today.
                    <div class="rectangle-25-1"></div>
                    <div class="rectangle-26-1"></div>
                </div>
            </div>
        </div>
        `;
    }
}

class ApprenticeTestimonials extends HTMLElement {
    constructor() {
        super();
        this.testimonials = [
            {
                text: "“As someone who is brand new to the industry, I was incredibly grateful for the chance to gain hands-on experience and learn along with the team”",
                author: "Gabriela Rollins, Data Science Apprentice",
            },
            {
                text: "“Computer Science major passionate about data analysis, merging fresh ideas with practical solutions to make a lasting impact in tech and social justice”",
                author: "Martin Solorio, Data Science Apprentice",
            },
            {
                text: "“Passionate about learning and creating in computer science. Honored to be an intern at ccpathways.org, inspiring compassion and helping others”",
                author: "Xiangqian, Front End Apprentice",
            },
            {
                text: "“Learning at ccpathways.org taught me tech hands-on, Python, HTML, CSS, and more. Grateful for the experience, the team, and the knowledge gained”",
                author: "Isadora Vasconcelos, Front End Apprentice",
            },
        ];
        this.currentIndex = 0;
        this.transitionDuration = 1000;
    }

    connectedCallback() {
        this.innerHTML = `
        <div class="customer-testimonials">
            <div class="frame">
                <div class="ready-to-get-started circularstd-bold-coconut-36px">OUR SUCCESS STORY</div>
                <p class="get-started-for-free material-themebodylarge">Here is what some of our apprentice have to say</p>
            </div>
            <img class="vector" src="${red_quotes}" alt="quotes image" />
            <div class="customer-testimonials-component frame-55">
                <div class="frame-98">
                    <p class="testimonial-text as-someone-who-is-b"></p>
                    <p class="testimonial-author circularstd-medium-white-24px"></p>
                </div>
            </div>
            <img class="quote-decor-1" src="${banner_decor_1_purple}" alt="decoration image" />
            <img class="quote-decor-2" src="${banner_decor_2_purple}" alt="decoration image" />
        </div>
        `;

        this.showCurrentTestimonial();
        this.adjustTestimonialText();
        this.initTestimonialChange();
    }

    showCurrentTestimonial() {
        const testimonialText = this.querySelector(".testimonial-text");
        const testimonialAuthor = this.querySelector(".testimonial-author");
        const currentTestimonial = this.testimonials[this.currentIndex];

        testimonialText.innerHTML = currentTestimonial.text;
        testimonialAuthor.innerText = currentTestimonial.author;
    }

    adjustTestimonialText() {
        const testimonialText = this.querySelector(".testimonial-text");
        const currentTestimonial = this.testimonials[this.currentIndex];

        if (window.innerWidth <= 768) {
            testimonialText.innerHTML = currentTestimonial.text.replace(/<br>/g, ' ');
        } else {
            testimonialText.innerHTML = currentTestimonial.text;
        }
    }

    initTestimonialChange() {
        setInterval(() => this.changeTestimonial(), 5000);
    }

    changeTestimonial() {
        const testimonialElement = this.querySelector(".customer-testimonials-component");
        testimonialElement.style.transition = `opacity ${this.transitionDuration}ms ease-in-out`;
        testimonialElement.style.opacity = 0;

        setTimeout(() => {
            this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
            this.showCurrentTestimonial();
            this.adjustTestimonialText();
            testimonialElement.style.opacity = 1;
        }, this.transitionDuration);
    }
}

customElements.define('home-carousel', HomeCarousel);
customElements.define('who-we-serve', WhoWeServe);
customElements.define('stories-of-impact', StoriesOfImpact);
customElements.define('apprentice-testimonials', ApprenticeTestimonials);
